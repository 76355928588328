// // rem等比适配配置文件
// // 基准大小
// const baseSize = 100;
// // 设置 rem 函数
// function setRem() {
//     // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
//     const scale = document.documentElement.clientWidth / 750;
//     // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
//     document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
// }
// // 初始化
// setRem();
// // 改变窗口大小时重新设置 rem
// window.onresize = function() {
//     setRem();
// }

!(function(e, t) {
  var i = e.documentElement,
    n = 'orientationchange' in window ? 'orientationchange' : 'resize',
    a = navigator.userAgent.match(/iphone|ipad|ipod/i),
    d = function() {
      var e = i.clientWidth,
        t = i.clientHeight;
      e && (1300 <= e && (e = 1300), (i.style.fontSize = (e / 1300) * 100 + 'px'), (i.dataset.percent = (e / 1300) * 100), (i.dataset.width = e), (i.dataset.height = t));
    };
  d(), a && e.documentElement.classList.add('iosx' + t.devicePixelRatio), e.addEventListener && t.addEventListener(n, d, !1);
})(document, window);
