// 当一个数据需要进行一系列变化的话就用getters,不是store里的必须配置
// 用于将state中的数据进行加工

export default {
  // 需求: 返回count的平方
  squareCount(state) {
    return state.count * state.count;
  },
};

// 在组件中调动
/**
 * $store.getters.squareCount
 */
