import Vue from 'vue';
import VueRouter from 'vue-router';

//路由懒加载----统一定义变量
const Index = () => import('../views/Index.vue');
const Rule = () => import('../views/Rule.vue');
const List = () => import('../views/List.vue');
const Work = () => import('../views/Work.vue');
const Form = () => import('../views/Form.vue');
const Detail = () => import('../views/Detail.vue');

//1、通过Vue.use(插件)，安装插件
Vue.use(VueRouter);

//2、创建VueRouter对象
const routes = [
  //默认页面
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/rule',
    name: 'rule',
    component: Rule,
    meta: {
      title: '规则奖励',
    },
  },
  {
    path: '/list',
    name: 'list',
    component: List,
    meta: {
      title: '互动专区',
    },
  },
  {
    path: '/work',
    name: 'work',
    component: Work,
    meta: {
      title: '我的做品',
    },
  },
  {
    path: '/form',
    name: 'form',
    component: Form,
    meta: {
      title: '技能设计',
    },
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail,
    meta: {
      title: '作品详情',
    },
  },
];

const router = new VueRouter({
  // mode: 'history', //使用html5的history模式，默认是hash模式
  base: process.env.BASE_URL,
  routes,
});

// 因为退出登录按钮本身在Home页面上，即路由地址为’ / ’ 的页面，退出登录后重定向到的页面依然是Home页面，也就是所谓的产生了冗余导航，vue-router便报错了
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

/**
 * 全局导航守卫
 * to:当前的路由页面
 * from:上一个的路由页面
 */
//前置守卫（guard）
router.beforeEach((to, from, next) => {

  if(from.query.channel_id){
    //路由切换时，如果没有就添加，有就跳过执行，添加固定参数
    if (!to.query.channel_id) {
        //准备一个跳转的query对象
        let query = to.query
        query.channel_id = from.query.channel_id
        next({
          path: to.path,
          query: query
        })
    } else {
        next()
    }
    }else{
    next()
    }
});

//后置钩子（hook）
router.afterEach((to, from) => {});

//3、将router对象传入到Vue实例
export default router;
