import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import jquery from 'jquery';
// import $ from 'jquery';

import 'common/rem';
import 'animate.css';
import 'assets/css/main.less';
import 'assets/css/animateml.less';

//安装toast插件
import toast from 'components/common/toast';
Vue.use(toast);

//less
import less from 'less';
Vue.use(less);

Vue.config.productionTip = false;

//jquery
Vue.prototype.$ = jquery;

import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper);
import 'swiper/dist/css/swiper.css';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
