// 有异步操作时要在mutations前添加一个actions步骤（比如接口请求）
// 本来在view直接commit函数，但是异步操作需要在actions里commit函数，在view中dispatch对应的actions

import * as TYPES from './mutations-types';
export default {
  //默认参数context(相当于store对象)----返回promise
  actUpdateInfo(context, payload) {
    // console.log(context);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        context.commit(TYPES.UPDATEINFO, payload.name); //执行修改
        console.log(payload.name); //传过来的参数
        context.dispatch('demo1', payload);
        resolve('修改成功了'); //告诉前面我修改成功了
      }, 1000);
    });
  },
  // actions里也可以写复杂逻辑，把方法拆分开来
  demo1(context, payload) {
    console.log('处理一些逻辑', context);
  },
};
