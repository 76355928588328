//该文件用于创建Vuex中最为核心的store

import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions'; //用于响应组件中的动作
import mutations from './mutations'; //用于操作数据{state}
import getters from './getters';

//1.应用Vuex插件（先use,后再创建对象）
Vue.use(Vuex);

//2.创建存储对象
const state = {
  count: 2,
  info: {
    name: 'molan',
  },
  age: 18,
  school: '湖北大学',

  token: '',
};

const store = new Vuex.Store({
  //2.1 定义状态（变量）
  state,
  //2.2 定义同步方法
  mutations,
  //2.3 有异步操作时在这里操作（比如接口请求）
  actions,
  //2.4 当一个数据需要进行一系列变化的话就用getters
  getters,
  //2.5 模块（按功能来区分模块）
  modules: {},
});

//3.导出store对象
export default store;
