// mutation里执行同步函数，当有异步操作时就要经过actions这一步，在actions里执行mutation里的函数

import * as TYPES from './mutations-types'
export default {
    //默认参数第一个参数state（就是上面的state），第二参数是传过来的参数
    [TYPES.INCREMENT](state, payload) {
        state.count++
    },
    //Vuex的响应式原理
    [TYPES.UPDATEINFO](state, payload) {
        state.info.name = payload; //name前面定义过了，所以可响应式
        state.info.des = '这是一个测试响应式的例子';
        // Vue.set(state.info, 'age', 12)
        // delete state.info.name;
        // Vue.delete(state.info, 'des')
    }
}